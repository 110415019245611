import classes from "../../ResultsList.module.css";
import Button from "src/components/UI/Button/Button";
import { FlightChainInfo } from "../FlightChainInfo/FlightChainInfo";
import HeartIcon from "src/components/UI/Icon/HeartIcon";
import { SortTagItem } from "../../ResultsList";
import ShareIcon from "src/components/UI/Icon/ShareIcon";
import { useLocaleContext } from "src/context/locale-context";
import {
  getFirstFlightChain,
  getLastFlightChain,
  getOldPricePerPerson,
  getPricePerPerson,
  getServiceFee,
  getTotalPricePerPerson,
  hasServiceFee,
  isDepartureArrivalDifferent,
  showSearchSeatsLeft,
} from "src/utils/results-utils";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { parseIntFromString } from "src/utils/utils";
import BaggageIndicatorsList from "./BaggageIndicatorsList";
import { DesktopBaggageDetails } from "../../../BaggageDetails/BaggageDetails";
import { BaggageDetailsTooltip } from "src/components/UI/Tooltip/Tooltip";
import { FlightCardTooltip } from "src/components/UI/Tooltip/Tooltip";
import { nanoid } from "@reduxjs/toolkit";

export function DesktopResultItem({
  item,
  isOneway,
  onShowDetailsHandler,
  onFavoriteHandler,
  onShareHandler,
  onSelectResult,
  isCheapest,
  isBest,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const searchParams = useSearchParams();
  const firstChain = getFirstFlightChain(item);
  const lastChain = getLastFlightChain(item);
  const priceWithoutFee = getPricePerPerson(item);
  const oldPriceWithoutFee = getOldPricePerPerson(item);
  const isDiffLocation = isDepartureArrivalDifferent(firstChain, lastChain);
  const isSorting = useSelector((state) => state.filterSort.isSorting);

  const adultNum = parseIntFromString(searchParams[0].get("p_adult"), 1);
  const childNum = parseIntFromString(searchParams[0].get("p_child"), 0);
  const infantNum = parseIntFromString(searchParams[0].get("p_infant"), 0);
  const passengerNum = adultNum + childNum + infantNum;

  const anchorIdTag = nanoid();

  let classnames = [
    "theme-results-item",
    classes.resultsItem,
    classes.loaded,
    classes.desktop,
  ];
  if (isCheapest && isBest) {
    classnames.push(`${classes.outlined} outlined`);
  }
  if (isSorting) {
    classnames.push(classes.isSorting);
  }

  const anchorId = "anchor-id-" + nanoid();

  return (
    <div className={classnames.join(" ")}>
      <div className="theme-results-item-decoration"></div>
      <FlightCardTooltip
        tooltipClass={classes.tooltip}
        anchorId={"anchor-id-save" + anchorIdTag}>
        <span className={classes.tooltipText}>
          <span>{stringRes["res.result.button.save"]}</span>
        </span>
      </FlightCardTooltip>
      <FlightCardTooltip
        tooltipClass={classes.tooltip}
        anchorId={"anchor-id-share" + anchorIdTag}>
        <span className={classes.tooltipText}>{stringRes["res.result.button.share"]}</span>
      </FlightCardTooltip>
      <div className={`${classes.left} ${isOneway ? classes.onetrip : classes.multitrip}`}>
        <div className={`${classes.sortTags} ${isCheapest || isBest ? "" : classes.none}`}>
          {isBest && <SortTagItem name={stringRes["res.result.tag.best"]} isBest />}
          {isCheapest && (
            <SortTagItem name={stringRes["res.result.tag.cheapest"]} isCheapest />
          )}
        </div>

        <div className={classes.extraButtons}>
          <button
            id={"anchor-id-save" + anchorIdTag}
            className={classes.heart}
            onClick={onFavoriteHandler}>
            <HeartIcon />
          </button>

          <button
            id={"anchor-id-share" + anchorIdTag}
            className={classes.share}
            onClick={onShareHandler}>
            <ShareIcon />
          </button>
        </div>
        <FlightChainInfo
          isOneway={isOneway}
          flightChain={firstChain}
          diffLocation={!isOneway && isDiffLocation}
          isDeparture
          className={classes.srcFlightInfo}
        />
        {!isOneway && (
          <FlightChainInfo
            flightChain={lastChain}
            diffLocation={isDiffLocation}
            className={classes.destFlightInfo}
          />
        )}
        <button className={classes.detailsButton} onClick={onShowDetailsHandler}>
          {stringRes["link.text.showDetails"]}
        </button>
      </div>

      <div className={classes.right}>
        <BaggageDetailsTooltip anchorId={anchorId}>
          <DesktopBaggageDetails baggage={item.baggage} />
        </BaggageDetailsTooltip>
        <div id={anchorId} className={classes.baggageIndicators}>
          <BaggageIndicatorsList flightPackage={item} />
        </div>

        <div className={classes.seatsPrice}>
          {oldPriceWithoutFee ? (
            <div>
              <span
                className={classes.oldPrice}>{`${oldPriceWithoutFee}${currencySymbol}`}</span>
              {`${priceWithoutFee}${currencySymbol} `}
            </div>
          ) : (
            <div>
              {`${priceWithoutFee} ${currencySymbol} `}
              <span>{stringRes["res.result.price.perPerson"]}</span>
            </div>
          )}

          {!!oldPriceWithoutFee && hasServiceFee(item) && (
            <div className={classes.priceBreakdown}>
              <span>{`${stringRes["res.result.fee.service"]} ${getServiceFee(
                item
              )} ${currencySymbol}`}</span>
              <span>{`${stringRes["res.result.price.final"]} ${getTotalPricePerPerson(
                item
              )} ${currencySymbol}`}</span>
            </div>
          )}

          {showSearchSeatsLeft(item.seats, passengerNum) && (
            <div
              className={
                classes.seatsLeft
              }>{`${item.seats} ${stringRes["res.result.seatsLeft"]}`}</div>
          )}
        </div>
        <div className={classes.selectButton}>
          <Button
            onClick={onSelectResult}
            primary
            wide
            name={stringRes["res.result.button.select"]}
          />
        </div>
      </div>
    </div>
  );
}
