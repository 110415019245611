import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonWithIcon from "src/components/UI/ButtonWithIcon/ButtonWithIcon";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import GuestMenu from "src/components/shared/menu/GuestMenu/GuestMenu";
import UserMenu from "src/components/shared/menu/UserMenu/UserMenu";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { filterSortActions } from "src/store/filter-sort";
import { customLog } from "src/utils/utils";
import classes from "./TopNavigation.module.css";

const TopNavigationItems = [
  { name: "main.nav.airtickets", to: "/" },
  { name: "main.nav.cheapflights", to: "/cheapflights" },
  { name: "main.nav.hotels", to: "/hotels" },
  { name: "main.nav.insurance", to: "/insurance" },
  { name: "main.nav.business", to: "/business" },
];

export function TopRightMenu({ withHeartButton }) {
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const { currentLocale, stringRes } = useLocaleContext();
  const { isAuthenticated, user, logout } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isFavorited, setFavorited] = useState(false);
  const { openModal } = useModalContext();

  const IMG_RESIZE_FACTOR = isMobile ? 1.25 : 1;

  const loginHandler = () => {
    openModal(MODAL_TYPE.loginOptions);
    setMenuOpen(false);
  };

  const logoutHandler = () => {
    logout();
    setMenuOpen(false);
  };

  const signupHandler = () => {
    openModal(MODAL_TYPE.createAccount);
    setMenuOpen(false);
  };

  const showUserMenu = isAuthenticated && isMenuOpen;
  const showGuestMenu = !isAuthenticated && isMenuOpen;

  const onClickFavoriteHandler = () => {
    setFavorited((prev) => !prev);
  };

  const onClickRegionalSettings = () => {
    customLog("regional settings clicked");
    openModal(MODAL_TYPE.regionalSettings);
  };

  const rightMenuItems = [
    {
      props: {
        imgEl: ({ imageClass }) => {
          return isFavorited ? (
            <svg
              style={{
                backgroundColor: "var(--ui-3)",
                borderRadius: "2px",
                outline: "2px var(--ui-3) solid",
              }}
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={19}
              viewBox="0 0 24 24"
              className="icon icon-tabler icons-tabler-filled icon-tabler-heart">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                fill="var(--primary-2)"
                opacity={0.7}
                d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 23 25"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-heart">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
            </svg>
          );
        },
        imgWidth: 23 * IMG_RESIZE_FACTOR,
        imgHeight: 22 * IMG_RESIZE_FACTOR,
      },
      onClick: onClickFavoriteHandler,
      name: "favorite",
    },
    {
      props: {
        img: require(`src/assets/img/flags/${currentLocale}.png`),
        imgWidth: 16 * IMG_RESIZE_FACTOR,
        imgHeight: 16 * IMG_RESIZE_FACTOR,
      },
      text: isMobile ? null : "EUR",
      name: "currency",
      onClick: onClickRegionalSettings,
    },
    {
      props: {
        imgEl: ({ imageClass }) => {
          return isAuthenticated && user.photoURL ? (
            <img
              className={imageClass}
              style={{ borderRadius: "100%", maxHeight: 19 }}
              alt="user"
              src={user.photoURL}
            />
          ) : (
            <svg
              className={imageClass}
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
            </svg>
          );
        },
        className: "menu-login-icon-btn",
      },
      text: isAuthenticated
        ? isMobile
          ? null
          : user.displayName || "Traveler"
        : stringRes["main.nav.login"],
      name: "user",
      onClick: isAuthenticated ? () => navigate("/dashboard") : loginHandler,
    },
    {
      props: {
        imgEl: ({ imageClass }) => (
          <svg
            className={imageClass}
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 6l16 0" />
            <path d="M4 12l16 0" />
            <path d="M4 18l16 0" />
          </svg>
        ),
      },
      onClick: () => setMenuOpen(true),
      name: "menu",
    },
  ].splice(withHeartButton ? 0 : 1);

  return (
    <Fragment>
      {showGuestMenu && (
        <GuestMenu
          onClose={() => setMenuOpen(false)}
          onLogin={loginHandler}
          onSignup={signupHandler}
        />
      )}
      <nav className={`${classes.menuRight} ${classes.container}`}>
        {rightMenuItems.map((menuItem) => (
          <ButtonWithIcon
            key={menuItem.name}
            name={menuItem.name}
            {...menuItem.props}
            onClick={menuItem.onClick}>
            {menuItem.text}
          </ButtonWithIcon>
        ))}
        {!isMobile && showUserMenu && (
          <UserMenu onClose={() => setMenuOpen(false)} onLogout={logoutHandler} />
        )}
      </nav>
    </Fragment>
  );
}

function TopNavigation() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();
  const currentpage = useLocation().pathname;
  const navbarNoBg = currentpage === "/" || currentpage === "/search/results";

  const onClickLogoHandler = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  return (
    <header
      id="main-app-top-nav"
      className={`${classes.menu} ${isMobile ? classes.mobile : classes.desktop} ${
        navbarNoBg ? "" : "opaque"
      }`}>
      <PaddedView>
        <nav className={classes.menuLeft}>
          <Link to={"/"} onClick={onClickLogoHandler}>
            <img
              className={classes.logo}
              alt="logo"
              src={
                `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
                `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
                `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/images/logo.svg`
              }
            />
          </Link>
          {!isMobile &&
            TopNavigationItems.map((item) => (
              <NavLink
                key={item.name}
                to={item.to}
                className={({ isActive }) => (isActive ? classes.active : "")}>
                {stringRes[item.name]}
              </NavLink>
            ))}
        </nav>
        <TopRightMenu withHeartButton={!isMobile} />
      </PaddedView>
    </header>
  );
}

export default TopNavigation;
