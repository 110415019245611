import cloneDeep from "lodash.clonedeep";
import isEqualWith from "lodash.isequalwith";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Card from "src/components/UI/Card/Card";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { TRIP_TYPE as TRIP } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import { ModalElementContext } from "src/context/modal-element-context";
import { searchDataActions } from "src/store/search";
import { customLog } from "src/utils/utils";
import { getSearchFormElementContentById } from "./SearchForm";
import classes from "./SearchForm.module.css";
import DesktopMultiSearchForm from "./elements/MultiSearchForm/DesktopMultiSearchForm";
import { DesktopSingleSearchForm } from "./elements/SingleSearchForm/DesktopSingleSearchForm";
import TravelTypeItemGroup from "./elements/TravelTypeItemGroup/TravelTypeItemGroup";

function dropdownReducer(state, action) {
  if (action.type === "OPEN_DROPDOWN") {
    return {
      targetTripId: action.tripId ? action.tripId : state.targetTripId,
      isElementClosing: false,
      currentElement: action.content,
      dropdownSelector: action.selector,
    };
  } else if (action.type === "CLOSE_DROPDOWN") {
    return { ...state, isElementClosing: true, currentElement: null };
  }
}

function cloneEssentialSearchData(searchData) {
  const clonedData = cloneDeep(searchData);
  delete clonedData.userInput;
  clonedData.trips.forEach((t) => {
    delete t.srcLocation.names;
    delete t.destLocation.names;
  });
  return clonedData;
}

function isEqualCustomized(obj1, obj2) {
  const customizer = (value1, value2, key) => {
    if (key === "id") {
      return true; // Skip comparing "id"
    } else if (typeof value1 === "string" && typeof value2 === "string") {
      return value1.toLowerCase() === value2.toLowerCase();
    }
  };

  return isEqualWith(obj1, obj2, customizer);
}

export function DesktopSearchForm({ isResultsPage, onSearchStart }) {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const elementRef = useRef();
  const inputRef = useRef({});

  const searchData = useSelector((state) => state.search, shallowEqual);
  const tripType = searchData.tripType;

  const prevSFData = useRef(cloneEssentialSearchData(searchData));
  const [showSearchButton, setShowSearchButton] = useState(!isResultsPage);
  const [checkAccommodation, setCheckAccommodation] = useState(false);
  const [checkDirectFlights, setCheckDirectFlights] = useState(false);

  const [dropdownState, dispatchDropdown] = useReducer(dropdownReducer, {
    targetTripId: searchData.trips[0].id,
    currentElement: null,
    isElementClosing: false,
    dropdownSelector: "",
  });

  const onSelectTripTypeHandler = (type) => {
    dispatch(searchDataActions.updateTripType(type));
    if (type !== TRIP.roundtrip) {
      dispatch(
        searchDataActions.updateEndDate({
          tripId: searchData.trips[0].id,
          date: "",
        })
      );
    }
  };

  useEffect(() => {
    const searchFormHeader = document.getElementById(
      "search-form-section" + (isResultsPage ? "-results" : "")
    );
    searchFormHeader.style.backgroundImage =
      `url("https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
      `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
      `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/images/header_bg.svg")`;

  }, [isResultsPage, window.SERVER_DATA.REACT_APP_TENANT_NAME]);

  useEffect(() => {
    customLog("apparently, SF data changed");
    const clonedSfData = cloneEssentialSearchData(searchData);
    if (isResultsPage && !isEqualCustomized(clonedSfData, prevSFData.current)) {
      setShowSearchButton(true);
      prevSFData.current = clonedSfData;
    }
  }, [isResultsPage, searchData]);

  const onOpenSelectionElement = (inputId, tripId) => {
    customLog("this is " + inputId);
    const content = getSearchFormElementContentById(inputId);
    dispatchDropdown({
      type: "OPEN_DROPDOWN",
      selector: inputId,
      tripId: tripId,
      content,
    });
  };

  const onCloseSelectionDropdown = useCallback(() => {
    customLog("closing a dropdown");
    dispatchDropdown({ type: "CLOSE_DROPDOWN" });
  }, []);

  const onCheckboxChangeHandler = (id, isChecked) => {
    if (id === "include-accommodation") {
      setCheckAccommodation(isChecked);
    } else if (id === "direct-flights-check") {
      setCheckDirectFlights(isChecked);
    }
  };

  const onClickSearchHandler = () => {
    const res = onSearchStart(
      tripType,
      searchData,
      checkAccommodation,
      onOpenSelectionElement,
      checkDirectFlights
    );
    if (res) {
      setShowSearchButton(!isResultsPage);
    }
  };

  let cardOpts = {};
  if (dropdownState.dropdownSelector === "passengers") {
    cardOpts = { padding: "1.55rem 1.25rem 1.25rem" };
  } else if (
    dropdownState.dropdownSelector === "dest-location" ||
    dropdownState.dropdownSelector === "src-location"
  ) {
    cardOpts = { padding: "1.875rem 1.25rem 1.25rem" };
  } else if (
    dropdownState.dropdownSelector === "start-date" ||
    dropdownState.dropdownSelector === "end-date"
  ) {
    cardOpts = { padding: "1.38rem 1.12rem 1.875rem", overflowY: "hidden" };
  }

  useEffect(() => {
    customLog(dropdownState.dropdownSelector);
    const clickAwayHandler = (e) => {
      // customLog(e.target);
      let r, isPassengers;
      if (dropdownState.dropdownSelector === "dest-location") {
        r = inputRef.current.dest;
      } else if (dropdownState.dropdownSelector === "src-location") {
        r = inputRef.current.src;
      } else if (dropdownState.dropdownSelector === "start-date") {
        r = inputRef.current[dropdownState.dropdownSelector];
      } else if (dropdownState.dropdownSelector === "end-date") {
        r = inputRef.current[dropdownState.dropdownSelector];
      } else if (dropdownState.dropdownSelector === "passengers") {
        r = inputRef.current.passengers;
        isPassengers = true;
      }

      if (
        elementRef.current &&
        r &&
        !elementRef.current.contains(e.target) &&
        !r.contains(e.target)
      ) {
        // customLog("EXECUTING CLICK AWAY MULTI");
        onCloseSelectionDropdown();
        if (isPassengers) {
          dispatch(searchDataActions.fillUserInputPassengerOptions());
        }
      }
    };

    const timerId = setTimeout(() => {
      document.addEventListener("mousedown", clickAwayHandler);
    }, 1);

    return () => {
      clearTimeout(timerId);
      document.removeEventListener("mousedown", clickAwayHandler);
    };
  }, [
    dispatch,
    elementRef,
    inputRef,
    dropdownState.dropdownSelector,
    onCloseSelectionDropdown,
  ]);

  const sfProps = {
    ref: inputRef.current,
    isButtonEnabled: showSearchButton,
    onSearchFormClick: onOpenSelectionElement,
    onStartSearch: onClickSearchHandler,
    onCheckboxChange: onCheckboxChangeHandler,
    checkValueAccom: checkAccommodation,
    isResultsPage: isResultsPage,
  };

  return (
    <PaddedView>
      {!isResultsPage && <h1>{stringRes["sf.desktop.title"]}</h1>}
      <TravelTypeItemGroup onSelected={onSelectTripTypeHandler} tripType={tripType} />
      {tripType === TRIP.multicity ? (
        <DesktopMultiSearchForm {...sfProps} />
      ) : (
        <DesktopSingleSearchForm {...sfProps} checkValueDirect={checkDirectFlights} />
      )}
      <ModalElementContext.Provider
        value={{
          onClose: onCloseSelectionDropdown,
          targetTripId: dropdownState.targetTripId,
        }}>
        {dropdownState.currentElement && (
          <SFDropdownElement
            isResultsPage={isResultsPage}
            isButtonEnabled={showSearchButton}
            ref={elementRef}
            opts={cardOpts}
            isOneway={tripType === TRIP.oneway}
            isClosing={dropdownState.isElementClosing}
            elementId={dropdownState.dropdownSelector}>
            {dropdownState.currentElement}
          </SFDropdownElement>
        )}
      </ModalElementContext.Provider>
    </PaddedView>
  );
}

const SFDropdownElement = forwardRef(function SFDropdownElement(
  { opts, isClosing, elementId, children, isResultsPage, isOneway, isButtonEnabled },
  ref
) {
  const { targetTripId } = useContext(ModalElementContext);
  const orderNum = useSelector((state) =>
    state.search.trips.findIndex((trip) => trip.id === targetTripId)
  );
  const maxOrder = useSelector((state) => state.search.trips.length);
  const dropdownClass =
    elementId === "passengers" ? classes["row" + (maxOrder - 1)] : classes["row" + orderNum];

  return (
    <div
      className={`${classes.flexbox} ${isResultsPage ? classes.results : ""} ${
        isOneway ? classes.oneway : ""
      } ${isButtonEnabled ? "" : classes.noSFButton}`}>
      <div className={classes.dropdownGrid}>
        <Card
          key={elementId + orderNum}
          opts={opts}
          isClosing={isClosing}
          ref={ref}
          className={`${classes.dropdown} ${dropdownClass} ${
            elementId ? classes[elementId] : ""
          }`}
          shadowLg
          {...(isResultsPage ? { cornerXSm: true } : { cornerSm: true })}>
          {children}
        </Card>
      </div>
    </div>
  );
});
