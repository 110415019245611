import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import classes from "./MobileResultsHeader.module.css";
import { OnewayArrow, RountripArrow } from "src/components/UI/Arrow/Arrow";
import { useSelector } from "react-redux";
import { TRIP_TYPE } from "src/constants";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import { getDisplayDateValue, getWeekdayFromDate } from "src/utils/date-utils";
import MobileModifySF from "../MobileModifySF/MobileModifySF";
import { MobileResultsFilters } from "../MobileResultsFilters/MobileResultsFilters";
import { MobilePriceAlertsButton } from "../MobileResultsFilters/MobilePriceAlertsButton";
import { TimeFilter } from "../TimeFilter/TimeFilter";
import { PriceFilter } from "../PriceFilter/PriceFilter";
import { StopFilter } from "../StopFilter/StopFilter";
import { DurationFilter } from "../DurationFilter/DurationFilter";
import { selectTripType } from "src/store/search";
import AirlinesFilter from "../AirlinesFilter/AirlinesFilter";
import AirportsFilter from "../AirportsFilter/AirportsFilter";
import ResultsSortingMobile from "../ResultsSortingMobile/ResultsSortingMobile";
import { LoadingIndicatorBar } from "src/components/UI/LoadingIndicatorBar/LoadingIndicatorBar";
import { useLocaleContext } from "src/context/locale-context";
import { BagsFilter } from "../BagsFilter/BagsFilter";
import { useTenantContext } from "src/context/tenant-context";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";

export const getModalElementContentById = (id) => {
  if (id === "modify-sf") {
    return <MobileModifySF />;
  } else if (id === "results-sort") {
    return <ResultsSortingMobile />;
  } else if (id === "stop-filter") {
    return <StopFilter />;
  } else if (id === "duration-filter") {
    return <DurationFilter />;
  } else if (id === "times-filter") {
    return <TimeFilter />;
  } else if (id === "airlines-filter") {
    return <AirlinesFilter />;
  } else if (id === "airports-filter") {
    return <AirportsFilter />;
  } else if (id === "price-filter") {
    return <PriceFilter />;
  } else if (id === "baggage-filter") {
    return <BagsFilter />;
  }
};

function MobileSearchSummary({ onModifySearch }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const lang = currentLocale.split("_")[0].toUpperCase();

  const { shortDateFormat } = useTenantContext();
  const trip = useSelector((state) => state.search.trips[0]);
  const tripType = useSelector(selectTripType);

  const src =
    trip.srcLocation?.names[lang] +
    (trip.srcLocation.isCity ? "" : " " + trip.srcLocation.iata);
  const dest =
    trip.destLocation?.names[lang] +
    (trip.destLocation.isCity ? "" : " " + trip.destLocation.iata);

  const weekdayNumStart = getWeekdayFromDate(trip.startDate, "YYYY-MM-DD");
  const weekdayNumEnd = getWeekdayFromDate(trip.endDate, "YYYY-MM-DD");

  const dateStart = getDisplayDateValue(trip.startDate, shortDateFormat);
  const dateEnd = getDisplayDateValue(trip.endDate, shortDateFormat);

  const start = `${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumStart]]} ${dateStart}`;
  const end = `${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumEnd]]} ${dateEnd}`;

  return (
    <div className={classes.summary}>
      <div>
        <p className={classes.destination}>
          <span>{src}</span>
          {tripType === TRIP_TYPE.roundtrip ? <RountripArrow /> : <OnewayArrow />}
          <span>{dest}</span>
        </p>
        <p className={classes.date}>
          {start}
          {tripType === TRIP_TYPE.roundtrip ? " - " + end : ""}
        </p>
      </div>
      <button type="button" onClick={onModifySearch}>
        {stringRes["res.mobile.summary.button"]}
      </button>
    </div>
  );
}

function reducer(state, action) {
  if (action.type === "OPEN_MODAL") {
    return {
      ...state,
      isElementClosing: false,
      currentElement: action.content,
    };
  } else if (action.type === "CLOSE_MODAL") {
    return { ...state, isElementClosing: true, currentElement: null };
  }
}

function MobileResultsHeader({ isRefreshing, resetTrigger }) {
  const [state, dispatch] = useReducer(reducer, {
    currentElement: null,
    isElementClosing: false,
  });
  const isReceivingData = useSelector((state) => state.filterSort.isReceivingData);

  const onOpenSelectionElement = (inputId) => {
    const content = getModalElementContentById(inputId);
    dispatch({ type: "OPEN_MODAL", content });
  };

  const elementRef = useRef(null);
  const [elementPositionType, setPositionType] = useState("relative");
  useEffect(() => {
    const onScroll = () => {
      const scrollThreshold = document
        .getElementById("main-app-top-nav")
        .getBoundingClientRect().bottom;
      const currentScroll = window.scrollY || document.documentElement.scrollTop;
      if (currentScroll > scrollThreshold) {
        setPositionType("sticky");
      } else {
        setPositionType("relative");
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onCloseSelectionScreen = useCallback(() => {
    dispatch({ type: "CLOSE_MODAL" });
  }, []);

  useEffect(() => {
    if (isRefreshing) {
      onCloseSelectionScreen();
      resetTrigger();
    }
  }, [isRefreshing, resetTrigger, onCloseSelectionScreen]);

  return (
    <section
      ref={elementRef}
      style={{ position: elementPositionType }}
      className="results-header">
      <MobileSearchSummary onModifySearch={() => onOpenSelectionElement("modify-sf")} />

      <ModalElementContext.Provider value={{ onClose: onCloseSelectionScreen }}>
        {state.currentElement && (
          <Modal isClosing={state.isElementClosing}>{state.currentElement}</Modal>
        )}
      </ModalElementContext.Provider>

      <div className={classes.buttonBar}>
        <MobilePriceAlertsButton />
        <MobileResultsFilters onClickOption={onOpenSelectionElement} />
      </div>
      <LoadingIndicatorBar isLoading={isReceivingData} height={3} />
    </section>
  );
}

export default MobileResultsHeader;
