import classes from "./DesktopResultsHeader.module.css";
import SearchForm from "../../search/SearchForm/SearchForm";
import { useSelector } from "react-redux";
import { LoadingIndicatorBar } from "src/components/UI/LoadingIndicatorBar/LoadingIndicatorBar";

function DesktopResultsHeader() {
  const isReceivingData = useSelector((state) => state.filterSort.isReceivingData);

  return (
    <section id="search-form-section-results" className={`results-header ${classes.header}`}>
      <SearchForm />
      <LoadingIndicatorBar isLoading={isReceivingData} height={4} />
    </section>
  );
}

export default DesktopResultsHeader;
